import React from "react";
import { bindActionCreators } from 'redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// material-ui icons
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

import * as CommonActions from '../../store/actions/common/common.jsx';
import * as CategoryActions from '../../store/actions/category/category.jsx';

import config from '../../store/config';
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return ({
        profile: state.auth.profile
    })
}

const mapDispatchToProps = (dispatch) => {
    return ({
        commonActions: bindActionCreators(CommonActions, dispatch),
        categoryActions: bindActionCreators(CategoryActions, dispatch)
    })
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: [],
            categories: []
        };
        this.editCategory = this.editCategory.bind(this);
    }
    componentDidMount() {
        this.getCategoris();
    }
    getCategoris() {
        this.props.categoryActions.getCategories().then(res => {
            this.setState({
                categories: res.data.categories
            })
        });        
    }
    editCategory(id) {
        this.props.history.push(`/edit_category/${id}`);
    }
    deleteCategory(id) {
        if(window.confirm("Are you sure to delete this category?")) {
            this.props.categoryActions.deleteCategory(id).then(res => {
                if(res.success) {
                    this.props.commonActions.alert('success', res.message)
                    this.getCategoris()
                } else {
                    this.props.commonActions.alert('danger', res.message)
                }
            }).catch(err => {
                this.props.commonActions.alert('danger', err.data.message)
            })
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Person />
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    this.state.categories && this.state.categories.length > 0 ?
                                        this.state.categories.map((category, key) => (
                                            <TableRow key={key}>
                                                <TableCell>{key + 1}</TableCell>
                                                <TableCell>{category.name}</TableCell>
                                                <TableCell>{category.phone_number}</TableCell>
                                                <TableCell>
                                                    <Button color="success" className={classes.actionButton} key={key+"0"} onClick={()=>this.editCategory(category.id)}>
                                                        <Edit className={classes.icon} />
                                                    </Button>
                                                    <Button color="danger" className={classes.actionButton} key={key+"1"} onClick={()=>this.deleteCategory(category.id)}>
                                                        <Close className={classes.icon} />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    : <TableRow><TableCell colSpan={6}>No Categories</TableCell></TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(extendedTablesStyle)(Category));