import React from "react";
import { bindActionCreators } from 'redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import * as AuthActions from '../../store/actions/auth/auth.jsx';
import * as CommonActions from '../../store/actions/common/common.jsx';
import * as CategoryActions from '../../store/actions/category/category.jsx';
import config from '../../store/config';
import { connect } from "react-redux";



const mapDispatchToProps = (dispatch) => {
    return ({
        authActions: bindActionCreators(AuthActions, dispatch),
        commonActions: bindActionCreators(CommonActions, dispatch),
        categoryActions: bindActionCreators(CategoryActions, dispatch)
    });
}

class EditCategory extends React.Component {

    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            id: "",
            name: "",
            phone_number: ""
        };

        this.handleInput = this.handleInput.bind(this)
        this.onClickStart = this.onClickStart.bind(this)
    }

    componentDidMount() {
        this.props.categoryActions.getCategory(this.props.match.params.id).then(res => {
            this.setState({
                id: res.id,
                name: res.name,
                phone_number: res.phone_number
            })
        }).catch(err => {
            this.props.commonActions.alert('danger', err.data.message)
        })
    }

    handleInput(key, value) {
        this.setState({
            [key]: value
        })
    }

    onClickStart() {
        if (!this.state.name || !this.state.phone_number) {
            this.props.commonActions.alert('danger', 'Inputs are not valid.');
        } else {
            let obj = {
                id: this.state.id,
                name: this.state.name,
                phone_number: this.state.phone_number,
            }
            this.props.categoryActions.editCategory(obj).then(res => {
                if (res.success) {
                    this.props.commonActions.alert('success', res.message)
                } else {
                    this.props.commonActions.alert('danger', res.message)
                }
            }).catch(err => {
                this.props.commonActions.alert('danger', err.data.message)
            })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Edit />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Edit Category</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <CustomInput
                                    labelText="Name"
                                    id="name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "text",
                                        value: this.state.name,
                                        onChange: e => this.handleInput('name', e.target.value)
                                    }}
                                />
                                <CustomInput
                                    labelText="Phone Number"
                                    id="phone_number"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "text",
                                        value: this.state.phone_number,
                                        onChange: e => this.handleInput('phone_number', e.target.value)
                                    }}
                                />
                                <Button
                                    color="rose"
                                    onClick={this.onClickStart}
                                >
                                    Submit
                                </Button>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default connect(null, mapDispatchToProps)(withStyles(regularFormsStyle)(EditCategory));