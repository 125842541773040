let config = {
//    API_ROOT_URL: "http://172.16.5.171:8084/api",
    API_ROOT_URL: "https://sms-api.farmhousefinishes.net/api",
//    API_ROOT_URL: "http://sms-api.theniceprogrammer.com/api",
//    API_ROOT_URL: "http://localhost:8000/api",
//    API_ROOT_URL: "http://39d8d137.ngrok.io/api",
    ROLE_ADMIN: 1,
    ROLE_SALER: 2,
    USER_STATUS_VALID: 1,
    USER_STATUS_INVALID: 0,
}

export default config