import AUTHAPI from '../AUTHAPI'

export const getCategories = (obj) => {
    return (dispatch) => {
        let data = {
            method: 'get',
            url: `/category`,
            data: obj
        }

        return AUTHAPI(data).then(res => {
            return res
        }).catch(err => {
            return err
        })
    }
}

export const getCategory = (id) => {
    return (dispatch) => {
        let data = {
            method: 'get',
            url: `/category/${id}`
        }

        return AUTHAPI(data).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }
}

export const editCategory = (obj) => {
    return (dispatch) => {
        let data = {
            method: 'post',
            url: `/category/${obj.id}/update`,
            data: obj
        }

        return AUTHAPI(data).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }
}

export const deleteCategory = (id) => {
    return (dispatch) => {
        let data = {
            method: 'post',
            url: `/category/${id}/delete`
        }

        return AUTHAPI(data).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }
}
